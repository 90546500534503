<template>
  <div v-if="club" class="container-fluid pictures-container">
    <b-card>
      <b-row no-gutters class="mt-2 mb-2">
        <b-col class="settings-title" cols="10">
          {{ $t('views.top-bar.settings.pictures.title') }}
        </b-col>
        <template v-if="club.photos.length">
          <b-col cols="2" class="upload-photo-size" align="right">
            <div
                class="pointer"
                @click="upload"
            >
              <span class="mr-2 social-light-blue"> {{ $t('views.top-bar.settings.pictures.upload-pic') }}</span>
              <i class="social-light-blue fa fa-upload "></i>
            </div>
          </b-col>
        </template>
      </b-row>
      <b-row>
        <template v-if="club.photos.length">
          <template v-for="(photo, i) of club.photos">
            <b-col
                :key="'photo' + i"
                class="mt-4"
                :cols="cols"
            >
              <div class="product-box">
                <div class="product-img">
                  <div class="container-over-img">
                    <img
                        width="100%"
                        class="picture-card"
                        :src="$filePath(photo.contentUrl)"
                    >
                    <div class="container-over">
                      <i
                          class="fa fa-star pointer"
                          :class="parseInt(photo.position) === 0 ? 'fa-star-active' : 'fa-star-inactive'"
                          @click="updatePhotoPosition(photo)"
                      >
                      </i>
                    </div>
                  </div>
                  <div class="product-hover">
                    <ul>
                      <li
                          class="pointer"
                          @click="deletePhoto(photo)"
                      >
                        <button
                            class="btn"
                            type="button"
                        >
                          <i class="icon-trash"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </b-col>
          </template>
        </template>
        <template v-else>
          <section class="ml-auto mr-auto">
            <div class="mb-3 row">
              <p>
                {{ $t('views.top-bar.settings.pictures.upload-pic-message') }}
              </p>
            </div>
            <div class="row ml-auto mr-auto">
              <d-button
                  text="views.top-bar.settings.pictures.upload-pic"
                  class="btn-text-to-display d-btn-sm btn d-btn-load-document font-text-title ml-auto mr-auto"
                  icon="fa fa-upload"
                  icon-size="14"
                  @on:button-click="upload"
              />
            </div>
          </section>
        </template>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {deleteClubPhoto, putClubPhoto} from "@api/doinsport/services/club/club-photo.api";

export default {
  props: {
    club: {
      type: Object,
      default: () => {
      },
    }
  },
  computed: {
    cols() {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 1180 && width > 828) {
        return 4;
      } else if (width < 828 && width >= 630) {
        return 6
      } else if (width < 630) {
        return 12;
      } else {
        return 3;
      }
    }
  },
  methods: {
    deletePhoto(photo) {
      deleteClubPhoto(photo.id).then(
          () => {
            this.$emit('on:reload-club')
          }
      );
    },
    updatePhotoPosition(photo) {
      putClubPhoto(photo.id, {position: 0}).then((response) => {
        this.$emit('on:reload-club');
      })
    },
    upload() {
      this.$upload(this.uploaded, {
        entry: 'club',
        url: JSON.parse(localStorage.getItem('selectedClub')),
        target: 'clubPhoto'
      });
    },
    uploaded(file) {
      this.$emit('on:reload-club');
    },
  }
}
</script>
<style>
.upload-photo-size .fa {
  font-size: 18px
}

.pictures-container .card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #C4C8D066;
  border-radius: 8px;
  opacity: 1;
}

.pictures-container .card .card-body {
  padding-top: 12px;
  padding-left: 35px;
  padding-right: 25px;
  padding-bottom: 12px;
  background-color: transparent;
}

.picture-card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  min-height: 300px;
  max-height: 300px;
  object-fit: cover;
}

.container-over-img {
  position: relative;
}

.container-over-img img {
  display: block;
}

.container-over-img .container-over {
  position: absolute;
  top: 11px;
  right: 14px;
  z-index: 9999;
}

.container-over {
  padding: 5px 9px;
  background-color: #FDFDFD;
  border-radius: 4px;
}

.fa-star-inactive {
  color: #BAB9B9;
  font-size: 20px;
  margin-top: 3px;
}

.fa-star-active {
  color: #FED105;
  font-size: 20px;
  margin-top: 3px;
}
</style>
